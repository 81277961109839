.headerContainer {
    padding: 10px 0;
    color: var(--color-ligth-gray);
}
.headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
}
.tourDatingGroup {
    display: flex;
    width: 14%;
    justify-content: space-between;
}
