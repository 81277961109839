.loginFormContainer {
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 1;
}

.loginForm {
    padding: 15px;
    border-radius: 25px;
    background-color: var(--color-form);
}

.formField label {
    color: var(--color-ligth-gray);
}

.formField div {
    color: var(--color-ligth-gray) !important;
}

.formField  fieldset{
    border: 1px solid var(--color-ligth-gray) !important;
    border-radius: 25px;


}

.btn {
    border:none !important;
    transition: border .05s ease-in-out;
}
.btn :hover {
    display: none !important;
color: black !important;

}
