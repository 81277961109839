.App {

  text-align: center;
  background-image: url("../public/1641-1024.jpg");
  background-repeat: no-repeat;
  background-size: 100vw;
  min-height: 100vh;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  background-position: center;
}

.headerWrap {
  margin-bottom: 50px;
}

.appForm {
  text-align: left;
}
