.logIn::after {
    content: "";
    width: 0.5em;
    height: 0.5em;
    display: inline-block;
    vertical-align: middle;
    border-left: 0.15em solid currentColor;
    border-bottom: 0.15em solid currentColor;
    transform: rotate(-45deg);
    margin-left: 0.8em;
    margin-top: -0.25em;
    cursor: pointer;
    transition: all .1s ease-in-out;
    transform-origin: 50% 50% 0;
}

.logInOpen::after {
    transform: rotate(135deg) translate(0px, -0.15em);
}

.logIn {
    padding: 10px 25px;
    border-radius: 25px;
    border: 1px solid currentColor;
    cursor: pointer;
    font-weight: 700;
}
