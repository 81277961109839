.infoContainer  , .link{
    font-size: clamp(10px, calc(0.8vw), 30px);
    white-space: nowrap;
    color: var(--color-ligth-gray) !important;
    width:100%;
    text-align: justify;
}

.link {
    font-size: clamp(10px, calc(0.8vw), 30px);
    text-decoration-color: var(--color-ligth-gray) !important;
}
