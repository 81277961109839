
.form {
    padding: 20px;
    border-radius: 25px;
    background-color: var(--color-form);
}

.formField {
    color: var(--color-ligth-gray) !important;
    padding-left: 0 !important;
}

.formField label {
    color: var(--color-ligth-gray) !important;
}

.formField p {
    margin: 0;
}

.formField fieldset {
    border: 1px solid var(--color-ligth-gray) !important;
    border-radius: 25px;
    min-width: 100px !important;
}

