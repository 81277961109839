@import '~@csstools/normalize.css';
*, *::after, *::before {
  box-sizing: border-box;
}

:root {
  --color-black : #000000;
  --color-white: #ffffff;
  --color-light-blue: #66c1ee;
  --color-ligth-gray: #4f5257;

  --color-form:  rgba(235,244,249,0.7);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

