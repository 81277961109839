.entryFormContainer {
    width: fit-content;


}
.formHeader {
    color: var(--color-ligth-gray);
    font-size: clamp(10px, calc(3.2vw),100px);
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    line-height: 76px;
    letter-spacing: 3px;
    margin-bottom: 0;
}

.freeWord {
    color: var(--color-light-blue);
}


.formDescription {
    font-size: clamp(16px, calc(1.1vw), 25px);
    color: var(--color-ligth-gray);
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
}
